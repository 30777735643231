<template>
  <div id="promocodes-view">
    <vs-popup classContent="popup-example" :title="`${$t('promoCode')} ${$t('details')}`" :active.sync="showModal" :key="promo.uuid">
      <template>
        <form>
          <div class="mb-4 px-4">
            <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
              <legend class="mx-3 px-2"><strong>{{$t('details')}}</strong></legend>
              <div class="vx-col my-2 md:w-2/3 w-full">
                <vs-input v-validate="'required'" class="w-full" name="promocode" :placeholder="$t('promoCode')" v-model="promo.code" />
                <span class="text-danger text-sm" v-show="errors.has('promocode')">{{ errors.first('promocode') }}</span>
              </div>
              <div class="vx-col my-2 md:w-1/3 w-full">
                <vs-input type="number" v-validate="'required'" class="w-full" name="discount" :placeholder="$t('fields.amount')" v-model="promo.percent" />
                <span class="text-danger text-sm" v-show="errors.has('discount')">{{ errors.first('discount') }}</span>
              </div>
            </fieldset>
          </div>
          <div class="vx-row mb-4">
            <div class="vx-col w-full">
              <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
                <legend class="mx-3 px-2"><strong>{{ `${$t('select')} ${$t('fields.type')}` }}</strong></legend>
                <div class="mx-4 mt-3 mb-5">
                  <v-select v-validate="'required'" :options="types" name="type" placeholder="Select one" v-model="promo.dep_type" :label="`name_${$i18n.locale}`" :reduce="opt => opt.value" />
                  <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="mb-4" :key="promo.uuid">
            <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
              <legend class="mx-3 px-2"><strong>{{ $t('attachTo') }}</strong></legend>
              <ul class="vx-row centerx px-4">
                <li class="mx-3 mt-4 mb-3 pr-2">
                  <vs-radio v-validate="'required'" name="type" v-model="promo.child_type" vs-value="all">{{ $t('all') }}</vs-radio>
                </li>
                <li class="mx-3 mt-4 mb-3 pr-2">
                  <vs-radio v-validate="'required'" name="type" v-model="promo.child_type" vs-value="brand">{{ $t('brand') }}</vs-radio>
                </li>
                <li class="mx-3 mt-4 mb-3 pr-2">
                  <vs-radio v-validate="'required'" name="type" v-model="promo.child_type" vs-value="category">{{ $t('category') }}</vs-radio>
                </li>
                <li class="mx-3 mt-4 mb-3 pr-2">
                  <vs-radio v-validate="'required'" name="type" v-model="promo.child_type" vs-value="products">{{ $t('product') }}</vs-radio>
                </li>
                <li class="mx-3 mt-4 mb-3 pr-2">
                  <vs-radio v-validate="'required'" name="type" v-model="promo.child_type" vs-value="collections">{{ $t('comboProduct') }}</vs-radio>
                </li>
              </ul>
              <span class="text-danger text-sm pl-4" v-show="errors.has('type')">{{ errors.first('type') }}</span>
              <div v-if="promo.child_type && promo.child_type !== 'all'" class="vx-row" :key="promo.child_type">
                <div class="vx-col w-full mb-4 mx-2">
                  <template v-if="promo.child_type !== 'campaign'">
                    <v-select multiple v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('selectOne')" v-model="promo.child_ids" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
                  </template>
                  <template v-else>
                    <v-select multiple v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('selectOne')" v-model="promo.child_ids" :label="`title_${$i18n.locale}`" :reduce="opt => opt.id" />
                  </template>
                  <span class="text-danger text-sm" v-show="errors.has('childId')">{{ errors.first('childId') }}</span>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="mb-4">
            <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
              <legend class="mx-3 px-2"><strong>{{ $t('fields.promoRule') }}</strong></legend>
              <ul class="vx-row centerx px-4">
                <li class="mx-4 mt-4 mb-3 pr-2">
                  <vs-radio v-validate="'required'" name="type" v-model="rule" vs-value="deadline">{{ $t('fields.timeRestricted') }}</vs-radio>
                </li>
                <li class="mx-4 mt-4 mb-3 pr-2">
                  <vs-radio v-validate="'required'" name="type" v-model="rule" vs-value="usecounts">{{ $t('fields.maxUseRestricted') }}</vs-radio>
                </li>
              </ul>
              <span class="text-danger text-sm pl-4" v-show="errors.has('type')">{{ errors.first('type') }}</span>
              <div v-if="rule === 'deadline'" class="vx-row px-4 mb-6 mt-1">
                <div class="vx-col w-1/2">
                  <flat-pickr v-validate="'required'" v-model="promo.from" :config="configdateTimePicker" class="w-full inputx" name="from" placeholder="Bashlangyc sene saylan" />
                  <span class="text-danger text-sm" v-show="errors.has('stockMinQt')">{{ errors.first('stockMinQt') }}</span>
                </div>
                <div class="vx-col w-1/2">
                  <flat-pickr v-validate="'required'" v-model="promo.to" :config="configdateTimePicker" class="w-full inputx" name="to" placeholder="Gutarysh sena saylan" />
                  <span class="text-danger text-sm" v-show="errors.has('stockMinQt')">{{ errors.first('stockMinQt') }}</span>
                </div>
              </div>
              <div v-if="rule === 'usecounts'" class="vx-row px-4 mb-4">
                <div class="vx-col my-2 w-full">
                  <vs-input v-validate="'required'" class="w-full" name="use_max" placeholder="Maximum usage" v-model="promo.use_max" />
                  <span class="text-danger text-sm" v-show="errors.has('use_max')">{{ errors.first('use_max') }}</span>
                </div>
              </div>

            </fieldset>
          </div>
          <div class="mb-4">
            <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
              <legend class="mx-3 px-2"><strong>{{ $t('fields.properties') }}</strong></legend>
              <ul class="vx-row centerx px-2">
                <li class="vx-col my-4 ml-2 mr-4 flex">
                  <vs-switch name="isActive" ref="isActive" v-model="promo.isActive" />
                  <label class="ml-3">{{ $t('isActive') }}?</label>
                </li>
              </ul>
            </fieldset>
          </div>
          <vs-row class="">
          <vs-button type="filled" @click.prevent="submitUpdate" class="mt-5 block">{{$t('update')}}</vs-button>
          <vs-button type="border" color="warning" @click.prevent="cancelUpdate" class="ml-auto mt-5 block">{{$t('cancel')}}</vs-button>
          </vs-row>
        </form>
      </template>
      
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <vs-table hoverFlat ref="table" :data="data" search stripe pagination :max-items="itemsPerPage">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-no-wrap">
            <div class="flex flex-wrap-reverse items-center">
              <router-link v-if="$acl.check('admin')" :to="{name: 'promoCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
              </router-link>
            </div>
          </div>
          <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ promos.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : promos.length }} of {{ queriedItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage=4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
        <template slot="thead">
          <vs-th class="pr-0"></vs-th>
          <vs-th class="pl-0">{{ $t('fields.code') }}</vs-th>
          <vs-th class="hidden lg:table-cell">{{ $t('fields.fromTo') }}</vs-th>
          <vs-th class="hidden lg:table-cell">{{ $t('fields.maxUse') }}</vs-th>
          <vs-th>{{ $t('discount.d') | capitalize }}</vs-th>
          <vs-th>{{ $t('fields.attached') }}</vs-th>
          <!-- <vs-th>Product</vs-th> -->
          <vs-th>{{ $t('fields.type') }}</vs-th>
          <vs-th>{{ $t('fields.active') }}?</vs-th>
          <vs-th v-if="$acl.check('admin')">{{ $t('fields.actions') }}</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
            <vs-td class="pr-0">
              <feather-icon v-if="data[indextr].use_max" icon="StarIcon" svgClasses="w-5 h-5 text-warning" />
            </vs-td>
            <vs-td class="pl-0" :data="data[indextr].code">
              {{ data[indextr].code }}
            </vs-td>
            <vs-td :data="data[indextr].from" class="hidden lg:table-cell">
              <template v-if="tr.from">
                {{ new Date(data[indextr].from).toLocaleString('ru-RU') }}<br>
                {{ new Date(data[indextr].to).toLocaleString('ru-RU') }}
              </template>
              <span v-else> --- </span>
            </vs-td>
            <vs-td :data="data[indextr].use_max" class="hidden lg:table-cell">
              <template v-if="tr.use_max">
                {{ data[indextr].use_max}}
              </template>
              <span v-else> --- </span>
            </vs-td>
            <vs-td :data="data[indextr].percent">
              %{{ data[indextr].percent }}
            </vs-td>
            <vs-td :data="data[indextr].child_type">
              {{ $t(data[indextr].child_type) | capitalize }} / {{ data[indextr].child_ids }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].child_ids">
              {{ data[indextr].child_ids }}
            </vs-td> -->
            <vs-td :data="data[indextr].dep_type">
                <vs-chip :color="data[indextr].dep_type === 'both' ? 'success' : data[indextr].dep_type === 'market' ? 'primary' : 'warning'">
                  {{ $t(data[indextr].dep_type) }}
                </vs-chip>
              </vs-td>
            <vs-td :data="data[indextr].isActive">
              <vs-chip :color="data[indextr].isActive ? 'success' : 'danger'">
                <feather-icon v-if="data[indextr].isActive" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>

            <vs-td v-if="$acl.check('admin')" class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click="editData(tr)" />            
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" />
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'

export default {
  components: {
    vSelect,
    flatPickr: () => import('vue-flatpickr-component')
  },
  data () {
    return {
      promos: [],
      itemsPerPage: 15,
      isLoading: true,
      showModal: false,
      isEditing: false,
      promo: {},
      rule: '',
      child_type: '',
      linkOptions: [],
      configdateTimePicker: {
        enableTime: true,
        time_24hr: true,
        locale: Russian
      },
      types: [
        {value: 'both', name_en: 'Both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {value: 'market', name_en: 'Market', name_tm: 'Market', name_ru: 'Маркет'},
        {value: 'express', name_en: 'Express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ]
    }
  },
  computed: {
    data () { return this.promos },
    currentPage () { return this.isLoading ? 0 : this.$refs.table.currentx },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.promos.length }
  },
  async created () { await this.fetchData() },
  watch: {
    async 'promo.child_type' (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (oldVal) this.promo.child_ids = []
        this.$validator.pause()
        this.$nextTick(() => {
          this.$validator.errors.clear()
          this.$validator.fields.items.forEach(field => field.reset())
          this.$validator.fields.items.forEach(field => this.errors.remove(field))
          this.$validator.resume()
        })
        switch (newVal) {
        case 'products': await this.$http.get('/products').then(response => { this.linkOptions = response.data.data })
          break
        case 'brand': await this.$http.get('/brands').then(response => { this.linkOptions = response.data.data })
          break
        case 'category': await this.$http.get('/categories').then(response => { this.linkOptions = response.data.data })
          break
        case 'campaign': await this.$http.get('/campaigns').then(response => { this.linkOptions = response.data.data })
          break
        case 'collections': await this.$http.get('/collections').then(response => { this.linkOptions = response.data.data })
          break
        }
      }
    },
    showModal () {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
    }
  },
  methods: {
    async fetchData () {
      await this.$http.get('/promos').then(response => {
        this.promos = response.data
        this.isLoading = false
      }).catch(() => { this.isLoading = false })
    },
    editData (tr) {
      this.$nextTick(() => {
        this.promo = Object.assign({}, {
          ...tr,
          createdAt: null,
          from: tr.from ? this.parseDate(tr.from) : null,
          to: tr.to ? this.parseDate(tr.to) : null,
          updatedAt: null
        })
        if (tr.use_max) this.rule = 'usecounts'
        else this.rule = 'deadline'
        this.showModal = true
      })
    },
    parseDate (date) {
      let newDate = date.split(',')
      newDate = [...newDate[0].split('.').reverse(), ...newDate[1].split(':')]
      newDate = newDate.map(el => Number(el))
      newDate[1] = newDate[1] - 1
      return new Date(...newDate)
    },
    async submitUpdate () {
      await this.$http.patch(`/promos/edit/${this.promo.uuid}`, this.promo)
        .then(() => {
          this.showModal = false
          this.fetchData()
        })
    },
    cancelUpdate () {
      this.showModal = false
      this.promo = {}
    },
    async deleteData (id) {
      await this.$http.delete(`/promos/delete/${id}`).then(() => {
        this.$vs.notify({
          title: 'Success',
          text: 'Promocode successfully deleted!',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
        this.fetchData()
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#promocodes-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>